import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useInterval, useMount } from 'react-use'
import moment from 'moment'
import ReactPlayer from 'react-player'
import DateTimePicker from 'react-datetime-picker'
import ProgressiveImage from 'react-progressive-image'

import { Layout, Player } from '../components'
import { container, padding, button, bgImage, colours, type } from '../styles/global'
import { media, useBreakpoint, isClient } from '../styles/utils'
import { formatDate, msToTime } from '../utils'

import { data } from '../data/home'
import { playerContext, isEventActive } from '../components/Player/utils'

const Index = (props) => {
    const { status, setStatus } = useContext(playerContext)
    const [eventActive, setEventActive] = React.useState(isEventActive());

    // Check if event is active 
 
    useInterval(() => {
        //console.log('checking', isEventActive())
        setEventActive(isEventActive())
    }, 1000)

    const renderEventStatusText = () => {
        let statusText;

        if (eventActive) return (
            <Heading>
                Now Playing
            </Heading>
        )

        if (!eventActive && status !== 'finished') return (
            <Heading>
                Starting soon
            </Heading>
        )

        if (status == 'finished') return (
            <Heading>
                Event ended
            </Heading>
        )
    }

    const renderStatus = () => {
        return (
            <Status>
                <Container>
                    <Heading>
                        {renderEventStatusText()}
                    </Heading>
                </Container>
            </Status>
        )
    }

    const renderHero = () => {
        return (
            <Hero>
                <Container>
                        {/* <Subheading
                            dangerouslySetInnerHTML={{__html: data.hero.subtitle}}
                        /> */}

                    <Heading
                        dangerouslySetInnerHTML={{__html: data.hero.heading}}
                    />

                    <Player/>

                    {status !== 'finished' && (
                        <Text
                            dangerouslySetInnerHTML={{__html: data.hero.description}}
                        />
                    )}
                </Container>
            </Hero>
        )
    }

    const renderDetails = () => {
        const credits = data.eventDetails.credits;

        return (
            <EventDetails>
                <Container>
                    <Heading
                        dangerouslySetInnerHTML={{__html: data.eventDetails.dates}}
                    />

                    <Credits>
                        <Subheading
                            dangerouslySetInnerHTML={{__html: credits.subtitle}}
                        />
                        <Heading
                            dangerouslySetInnerHTML={{__html: credits.heading}}
                        />
                        <Text
                            dangerouslySetInnerHTML={{__html: credits.otherCredits}}
                        />
                    </Credits>
                </Container>
            </EventDetails>
        )
    }  
    
    const renderRadio = () => {
        return (
            <Radio>
                <Container>
                    <Subheading
                        dangerouslySetInnerHTML={{__html: data.radio.subtitle}}
                    />
                    <Heading
                        dangerouslySetInnerHTML={{__html: data.radio.heading}}
                    />
                </Container>
            </Radio>
        )
    }  

    const renderMoreInfo = (params) => {
        return (
            <MoreInfo>
                <Container>
                    <Heading
                        dangerouslySetInnerHTML={{__html: data.moreInfo.heading}}
                    />

                    {data.moreInfo.button && (
                        <Button
                            href={data.moreInfo.button.link}
                            target={'_blank'}
                        >   
                            <Icon 
                                src={require('../assets/images/link-arrow.svg')}
                            />

                            {data.moreInfo.button.text}
                        </Button>
                    )}
                </Container>
            </MoreInfo>
        )        
    }

	return (
		<Layout>
            <Wrapper>
                {renderStatus()}
                {renderHero()}
               
                {/* {renderRadio()} */}
                {renderMoreInfo()}
                {renderDetails()}
            </Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.h3``
const Subheading = styled.h4``
const Text = styled.div``
const Info = styled.div``
const Button = styled.a``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`

const Icon = styled.img``

// Layout

const Wrapper = styled.div`
    display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
    width: 100%;
`

const Container = styled.div`
    ${container}
    ${padding}
    flex-direction: column;
    align-items: flex-start;
`

// Status   

const Status = styled.div`  
    display: flex;
    justify-content: center;
    background: black;
    border-bottom: 1px solid ${colours.pink};
    width: 100%;

    ${Container} {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 80px;

        ${Heading} {
            ${type.heading2};
            color: white;
        }
    }
`

const Stats = styled.div`  
    display: flex;
    justify-content: center;

`


// Hero

const Hero = styled.div`
    display: flex;
    justify-content: center;
    background: black;
    width: 100%;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);

    ${Container} {
        display: flex;
        padding-top: 35px;
        padding-bottom: 55px;

        ${media.phone`
            padding-bottom: 45px;
        `}

        > * {
            color: white;
        }

        ${Subheading} {
            ${type.caption};
        }

        ${Heading} {
            ${type.heading1};
            margin-top: 10px;

            span {
                font-weight: 600;
            }
        }

        ${Text} {
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            max-width: 714px;

            font-weight: 600;
            font-size: 22px;
            line-height: 1.35em;

            span {
                color: #B5B5B5; 
                margin-top: 16px;
            }

            ${media.phone`
                font-size: 15px;
            `}
        }
    }
`

// Player

const PlayerWrapper = styled.div`
    height: 450px;
`

// Event Details

const Credits = styled.div``

const EventDetails = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    

    ${Container} {
        display: flex;
        padding-top: 32px;
        padding-bottom: 90px;

        ${media.phone`
            padding-bottom: 60px;
        `}

        > ${Heading} {
            display: flex;
            flex-direction: column;
            ${type.heading2};

            span {
                font-weight: 400;
                margin-top: 5px;
            }
        }

        ${Credits} {
            margin-top: 100px;

            ${media.phone`
                margin-top: 75px;
            `}

            ${Subheading},
            ${Text} {
                ${type.heading3};

                a {
                    color: ${colours.pink}
                }
            }

            ${Heading} {
                a {
                    font-weight: 800;
                    font-size: 45px;
                    line-height: 56px;
                    margin-top: 5px;
                    color: black;
                }
            }

            ${Text} {
                margin-top: 32px;

                ${media.phone`
                    margin-top: 24px;
                `}

            }
        }
    }
`

// Radio

const Radio = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    ${Container} {
        display: flex;
        padding-top: 25px;
        padding-bottom: 32px;

        ${Subheading} {
            ${type.heading3};
        }

        ${Heading} {
            font-weight: 800;
            font-size: 34px;
            line-height: 42px;
        }
    }
`

// More Info

const MoreInfo = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background: ${colours.lightPink};

    ${Container} {
        display: flex;
        padding-top: 41px;
        padding-bottom: 48px;

        ${media.phone`
            padding-top: 21px;
            padding-bottom: 21px;
        `}

        ${Heading} {
            font-weight: 600;
            font-size: 36px;
            line-height: 44px;

            ${media.phone`
                font-size: 24px;
                line-height: 30px;
            `}
        }

        ${Button} {
            font-weight: 600;
            font-size: 16px;
            line-height: 1.35em;
            border-bottom: 1px solid ${colours.pink};
            padding-bottom: 9px;
            margin-top: 90px;
            color: black;

            ${media.phone`
                margin-top: 55px;
                margin-left: auto;
            `}

            ${Icon} {
                margin-right: 12px;
            }
        }
    }
`

export default Index
