import { stripIndent } from "common-tags";

export const data = {
    hero: {
        subtitle: `BRISBANE FESTIVAL AND BRISBANE CITY COUNCIL PRESENT`,
        heading: stripIndent`
            Sunsuper Night Sky<br>
            <span>Soundtrack</span>
        `,
        description: stripIndent`
            <span>Hear Robin Fox’s composition to accompany <i>Sunsuper Night Sky</i> in synchronisation with the display.</span>
        `
    },
    eventDetails: {
        dates: stripIndent`
            4 – 26 Sept<br>
            Friday & Saturday, 7pm – 9pm<br>
            <span>Every half hour</span>
        `,
        credits: {
            subtitle: `Audio-visual artist`,
            heading: `<a href="https://robinfox.com.au" target="_blank">Robin Fox</a>`,
            otherCredits: stripIndent`
                Lasers supplied by <a href="http://www.oracle-liquid.com.au/" target="_blank">Oracle-Liquid</a><br>
                Web app & development by <a href="https://morebymore.com" target="_blank">More Studio</a>
            `,
        }
    },
    radio: {
        subtitle: `Other ways to listen`,
        heading: `95.3 MHz FM`,
    },
    moreInfo: {
        heading: `Sunsuper Night Sky`,
        button: {
            text: 'Find out more',
            link: 'https://www.brisbanefestival.com.au/whats-on/sunsuper-night-sky'
        }
    }
}